import { Pipe, PipeTransform } from '@angular/core';

/**
 * Generated class for the ShortenPipe pipe.
 *
 * See https://angular.io/api/core/Pipe for more info on Angular Pipes.
 */
@Pipe({
    name: 'shorten',
})
export class ShortenPipe implements PipeTransform {
    /**
     * Takes a value and shorten it.
     */
    transform(value: any = '', limit: number, ellipsis = '...'): any {
        let shortenedString = '';
        if (value?.length > limit) {
            shortenedString = value.substr(0, limit) + ellipsis;
        }
        return shortenedString;
    }
}
